<template>

    <div
      v-if="isAuthorized"
      class="header__login"
      v-click-outside="() => (isDropdownShown = false)">
      <div class="header__loginUser">
        <div class="header__loginUser-inner">
          <div
            class="header__loginUser-dropdown dropdown"
            :class="{ opened: isDropdownShown }">
            <div class="header__loginUser-dropdownInner"></div>
            <span
              class="header__loginUser-dropdownClose"
              @click="isDropdownShown = false"></span>
            <button
              data-bs-toggle="dropdown"
              aria-expanded="false"
              class="header__profile-link"
              @click="isDropdownShown = !isDropdownShown">
              <div class="header__login-left">
                <div class="header__login-name">
                  {{ fullName }}
                </div>
                <div class="header__login-info">
                  <div class="header__login-status">
                    <img src="@/assets/img/status/crown-blue.svg" alt="" />
                    {{ $t(`statuses.${userStatus}`) }}
                  </div>
                  <div class="header__login-bonus">
                    <img src="@/assets/img/icon/prize.svg" alt="" />
                    {{ userBalance }}
                  </div>
                </div>
              </div>
              <div class="header__login-avatar">
                <div class="header__status-icon"></div>
                <img :src="avatar" alt="" />
              </div>
            </button>

            <div
              class="dropdown-menu header__profile-menu"
              :class="{ show: isDropdownShown }"
              @click="isDropdownShown = false">
              <div class="profile-menu__top">
                <router-link
                  to="/user/internal-account"
                  class="profile-menu__block">
                  <strong> Статус </strong>
                  <span>
                    {{ $t(`statuses.${userStatus}`) }}
                  </span>
                </router-link>
              </div>

              <div class="profile-menu__bottom">
                <a
                  :href="profileLink"
                  class="profile-menu__link">
                  <div class="profile-menu__link-icon">
                    <img
                      src="@/assets/img/icon/user-menu.svg"
                      alt=""
                      style="width: 9px"
                    />
                  </div>
                  Перейти в профиль клиента
                </a>

                <a
                  v-if="false"
                  :href="profileLink"
                  class="profile-menu__link">
                  <div class="profile-menu__link-icon">
                    <img
                      src="@/assets/img/icon/profile-menu.svg"
                      alt=""
                      style="width: 13px" />
                  </div>
                  Перейти в профиль специалиста
                </a>

                <a
                  v-if="isAdmin || isSpecialist || isWebmaster"
                  :href="crmLink"
                  class="profile-menu__link">
                  <div class="profile-menu__link-icon">
                    <img
                      src="@/assets/img/icon/crm-icon.svg"
                      alt=""
                      style="width: 8px" />
                  </div>
                  Перейти в crm
                </a>

                <button
                  type="button"
                  class="profile-menu__link"
                  @click="signOut">
                  <div class="profile-menu__link-icon">
                    <img
                      src="@/assets/img/icon/login-black.svg"
                      alt=""
                      style="width: 11px" />
                  </div>
                  Выход
                </button>
              </div>
            </div>
          </div>

          <router-link to="/notes" class="header__notes-link">
            <span v-if="unreadCount" class="header__notes-counter">{{
              unreadCount
            }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <span
      v-else
      @click="$bvModal.show('auth-modal')"
      class="header__login">
      <i class="login-icon" />
      <span>Вход / Регистрация</span>
    </span>

</template>

<script>
import ClickOutside from "vue-click-outside";
import { auth } from "@/helpers/auth";

export default {
  name: "AccountAuthChecker",
  directives: { ClickOutside },

  data() {
    return {
      isDropdownShown: false,
    };
  },

  computed: {
    isAuthorized() {
      const accountModule = useAccount();
      return accountModule.userId;
    },

    avatar() {
      const accountModule = useAccount();
      return accountModule.avatar;
    },

    userStatus() {
      const accountModule = useAccount();
      return accountModule.status;
    },

    userBalance() {
      const accountModule = useAccount();
      return accountModule.balance;
    },

    fullName() {
      const accountModule = useAccount();
      return accountModule.fullName;
    },

    isAdmin() {
      const accountModule = useAccount();
      return accountModule.isAdmin;
    },

    isSpecialist() {
      const accountModule = useAccount();
      return accountModule.isSpecialist;
    },

    isWebmaster() {
      const accountModule = useAccount();
      return accountModule.isWebmaster;
    },

    crmLink() {
      const accountModule = useAccount();
      return accountModule.crmLink;
    },

    profileLink() {
      return import.meta.env.VITE_HOME_REDIRECT_URL || "/";
    },

    unreadCount() {
      if (!process.client) {
        return;
      }
      const bellModule = useBellModule();
      return bellModule.unreadCount;
    },
  },

  created() {
    if (!process.client) {
      return;
    }
    const bellModule = useBellModule();
    const accountModule = useAccount();
    bellModule.init();
    if (accountModule.userId) {
      bellModule.loadUnreadCount();
    }
  },

  methods: {
    signOut() {
      const chatsModule = useChatsModule();
      const accountModule = useAccount();
      chatsModule.destroy();
      auth.logout();
      accountModule.signOut();
      if (this.$route.name === "Home") return;

      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__login {
    &-name {
      text-decoration: none;
    }

    &-avatar {
      background-color: $white;
      border-radius: 50%;
    }
  }

  &__notes-link {
    background: url(@/assets/img/icon/alarm-lightgrey.svg) no-repeat;
  }

  &__login {
    &:hover {
      opacity: 1;
    }
  }

  &__loginUser-dropdown &__profile-menu {
    top: calc(100% + 15px) !important;
    left: 50% !important;
    transform: translateX(-50%)!important;
    margin-left: 15px!important;
    @media (max-width: 991px) {
      top: calc(100% + 4px) !important;
      margin-left: 0!important;
    }
  }

  &.sticky,
  &.header2 {
    .header {
      &__notes-link {
        background: url(@/assets/img/icon/alarm-grey.svg) no-repeat;
      }
    }
  }
}

.profile-menu__link {
  text-decoration: none;
  color: inherit;
}

.profile-menu__block {
  color: inherit;
  text-decoration: none;
}

.header__login-avatar img {
  border-radius: 50%;
}

.header__notes-link {
  position: relative;
}

.header__notes-counter {
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -8px;
  background: $black;
  height: 17px;
  color: $white;
  font-size: 11px;
  line-height: 17px;
  padding: 0 7px;
  border-radius: 50%;
}
</style>
