import { setupFetch, getAxiosResponse } from "@/helpers/fetch";
import Cookie from "js-cookie";

const apiUrl = import.meta.env.VITE_API_PROXY_URL && !process.client && !/^http/.test(import.meta.env.VITE_API_URL)
    ? `${import.meta.env.VITE_API_PROXY_URL}${import.meta.env.VITE_API_URL}`
    : import.meta.env.VITE_API_URL;
const _fetch = setupFetch(`${apiUrl}/noty/api/v1`, {
  headers: { "Content-Type": "application/json" },
});

class NotyApi {
  getUnreadMessagesCount() {
    return _fetch("/getUnreadMessagesCount", {
      method: "POST",
      body: JSON.stringify({
        access_token: Cookie.get("accessToken"),
      }),
    }).then((response) => getAxiosResponse(response));
  }

  getInboxMessages(opts) {
    return _fetch("/getInboxMessages", {
      method: "POST",
      body: JSON.stringify({
        ...opts,
        access_token: Cookie.get("accessToken"),
      }),
    }).then((response) => getAxiosResponse(response));
  }

  markMessageRead(opts) {
    return _fetch("/markMessageRead", {
      method: "POST",
      body: JSON.stringify({
        ...opts,
        access_token: Cookie.get("accessToken"),
      }),
    }).then((response) => getAxiosResponse(response));
  }
}

export const notyApi = new NotyApi();
