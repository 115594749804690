import { Model } from "@/stores/models/Model";
import { getImageFromSrc } from "@/helpers/images";

export class Chat extends Model {
  constructor(raw = {}) {
    super();
    this.id = String(raw.id);

    this.specialistId = String(raw.specialist_id || "");
    this.specialistName = String(raw.specialist_name || "");
    this.specialistAvatar = getImageFromSrc(raw.specialist_avatar);
    this.specialistJobTitle = String(raw.specialist_job_title || "");
    this.specialistPresence = String(raw.specialist_presence || "offline");

    this.status = String(raw.status || "");
    this.totalMessagesCount = Number(raw.total_messages_count || 0);
  }

  get isOnline() {
    return this.specialistPresence === Chat.presence.online;
  }

  get isPending() {
    return this.status === Chat.states.pending;
  }

  get isActive() {
    return this.status === Chat.states.active;
  }

  get isClosed() {
    return this.status === Chat.states.closed;
  }

  static get states() {
    return {
      pending: "pending",
      active: "active",
      closed: "closed",
    };
  }

  static get presence() {
    return {
      online: "online",
      offline: "offline",
    };
  }
}
