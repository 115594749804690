<template>
  <header
    v-if="!isAuth || isTablet"
    ref="header"
    :class="{
      header2: $route.name !== 'index',
      'header--authorized': isAuthorized,
    }"
    class="header not-hide"
    @scroll="handleScroll">
    <div class="header__top">
      <div class="container">
        <div class="header__top-inner">
          <div class="header__top-left">
            <div class="header__left-inner">
              <div class="header__logo">
                <router-link to="/" class="header__logo-link d-block">
                  <img
                    src="@/assets/img/logo-white.svg"
                    alt=""
                    class="logo-white" />
                </router-link>
              </div>
              <div class="header__logo-text">Бронируй на своих условиях!</div>
              <router-link to="/" class="header__logo-mobile">
                <img src="@/assets/img/logo-black.svg" alt="" />
              </router-link>
            </div>
          </div>
          <div class="header__top-right">
            <div class="header__right-inner">
              <client-only>
                <router-link
                  v-if="!isAdmin"
                  tag="button"
                  to="/business"
                  class="add-object__btn btn">
                  <client-only><i class="fas fa-plus"></i></client-only>
                  Добавить свой объект
                </router-link>
              </client-only>
              <div class="salon-location">
                <div class="salon-location__block">
                  <client-only>
                    <button
                      class="salon-location__btn btn dropdown-toggle d-flex align-items-center"
                      @click="isSelectCityOpen = !isSelectCityOpen">
                      <i class="local-icon"></i>
                      <div class="location-name">
                        {{ city }}
                      </div>
                    </button>
                  </client-only>

                  <CitySelectModal
                    v-if="isSelectCityOpen"
                    :city="city"
                    @select="getCityFromLocation"
                    @close="isSelectCityOpen = false" />
                </div>
              </div>
              <client-only>
                <AccountAuthChecker />
              </client-only>
              <div class="language-site">
                <div
                  class="dropdown"
                  :class="{ show: isLangDropdownShow }">
                  <button
                    id="headerMenuLanguage"
                    class="d-flex language-site__btn align-items-center dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="isLangDropdownShow = !isLangDropdownShow">
                    <div class="dropdown-menu__language-choose rus">
                      <img src="@/assets/img/flag/flag-for-russia.svg" alt="" />
                    </div>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu__language dropdown-menu-center"
                    :class="{ show: isLangDropdownShow }"
                    aria-labelledby="headerMenuLanguage">
                    <div class="dropdown-menu__language-items">
                      <div class="dropdown-menu__language-item">
                        <img
                          src="@/assets/img/flag/flag-for-russia.svg"
                          alt=""
                          @click="isLangDropdownShow = false" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header__bottom">
      <div class="container">
        <div class="header__bottom-inner d-flex justify-content-between align-items-baseline">
          <div class="header__bottom-nav header__nav w-100">
            <div class="header__nav-items">
              <router-link
                v-for="item in filteredCategories"
                :key="item.id"
                class="header__nav-item"
                :class="{ active: isMenuItemActive(item) }"
                :to="{
                  path: '/search',
                  query: { categoryId: item.id },
                }">
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <div v-if="isAuthorized" class="header__timer">
        <Timer />
      </div>
    </client-only>
  </header>
</template>

<script setup>
import AccountAuthChecker from "./components/AccountAuthChecker";
import CitySelectModal from "./components/CitySelectModal";
import Timer from "@/components/Timer/Timer.vue";

import { onMounted, computed } from "vue";

const searchStore = useSearch();
const accountModule = useAccount();

const isSelectCityOpen = ref(false);

async function checkCity() {
  await accountModule.initCookies();
  if (accountModule.city) return;

  await getCityFromLocation();
  isSelectCityOpen.value = true;
}
if (process.client) {
  checkCity();
}

async function getCityFromLocation(city) {
  try {
    let { data } = await mainApi.getAddressFromIp({ city });
    if (!city) {
      data = await mainApi.getAddressFromIp({ city: data.cityName });
      data = data.data;
    }
    accountModule.changeCity(data || {});
  } catch (e) {
    console.error(e);
    accountModule.changeCity({});
  }
}

const filteredCategories = computed(() => {
  return searchStore.serviceCategories.sort(
    (a, b) => a.sortInHeader - b.sortInHeader
  );
});
</script>
<script>

import { mainApi } from "@/stores/api/main-api";
import ClickOutside from "vue-click-outside";

export default {
  name: "Header",
  directives: { ClickOutside },

  props: {
    isAuth: Boolean,
  },

  data() {
    return {
      scrollPrev: 0,
      isTablet: false,
      isLangDropdownShow: false,
    };
  },

  computed: {
    city() {
      const accountModule = useAccount();
      return accountModule.city;
    },

    isAdmin() {
      const accountModule = useAccount();
      return accountModule.isAdmin;
    },

    isAuthorized() {
      const accountModule = useAccount();
      return accountModule.userId;
    },
  },

  async created() {

  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", () => {
      this.isTablet = window.innerWidth < 992;
    });
    this.isTablet = window.innerWidth < 992;
  },

  beforeDestroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    isMenuItemActive(item) {
      if (this.$route.name !== "search") return false;
      return this.$route.query.categoryId === String(item.id);
    },

    handleScroll() {
      const header = this.$refs.header;
      if (!process.client || !header) {
        return;
      }
      if (this.isTablet || !this.isAuth) {
        const scrolled = window.scrollY;

        if (scrolled > 70 && scrolled > this.scrollPrev) {
          header.classList.add("out");
        } else {
          header.classList.remove("out");
        }

        if (scrolled > 5 && !header.className.includes("sticky")) {
          header.classList.add("sticky");
        } else if (scrolled < 5) {
          header.classList.remove("sticky");
        }
        this.scrollPrev = scrolled;
      }
    },
  },
};
</script>

<style lang="scss">
@import "styles/header";

.crm-link {
  margin-right: 20px;
}

.header__logo img {
  max-width: 220px;
}

.header__nav-items {
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .header__logo-mobile {
    display: block;
    max-width: 160px;
  }
}
</style>
