import { notyApi } from "@/stores/api/noty-api";
import { WS } from "@/services/ws";
import Cookie from "js-cookie";

export const useBellModule = defineStore("bellModule", {
  state: () => ({
    unreadCount: 0,
    isInitted: false,
  }),

  getters: {},

  actions: {
    init() {
      if (!process.client || this.isInitted) return;
      this._ws = new WS(
        import.meta.env.VITE_NOTIFICATIONS_URI ||
          location.origin.replace("http", "ws") + "/noty/notifications",
        Cookie.get("accessToken")
      );
      this._ws.connect();
      this._ws.addEventListener("notification:new_inbox_message", () =>
        this.loadUnreadCount(true)
      );
      this.isInitted = true;
    },

    async loadUnreadCount(showToast = false) {
      if (!process.client) return;
      const { $doNoty: doNoty } = useNuxtApp();
      const { data } = await notyApi.getUnreadMessagesCount();
      if (data.count !== this.unreadCount && showToast) {
        doNoty.info(
          `Вам поступило новое уведомление! У вас ${data.count} непрочитанных уведомлений`
        );
      }
      this.unreadCount = Number(data.count || 0);
    },
  },
});
