import Cookie from "js-cookie";

export class WS extends EventTarget {
  webSocket = null;
  constructor(url, token) {
    super();
    this.url = url;
    this.accessToken = token;
  }
  connect() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            const channel = new BroadcastChannel("notification-messages");
            channel.onmessage("message", (e) => this.handleMessage(e.data));
          } else {
            this.connectWS();
          }
        })
        .catch(() => this.connectWS());
    } else {
      this.connectWS();
    }
  }
  connectWS() {
    this.webSocket = new WebSocket(this.url);
    this.webSocket.onmessage = (e) => this.handleMessage(JSON.parse(e.data));
    this.webSocket.onclose = (e) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(() => this.connectWS(), 2500);
    };
    this.webSocket.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this.webSocket.close();
    };
  }
  registerInboxNotifications(id) {
    if (this.webSocket && this.url && this.accessToken) {
      this.webSocket.send(
        JSON.stringify({ id, access_token: this.accessToken })
      );
    }
  }
  handleMessage(data) {
    switch (data.type) {
      case "id":
        Cookie.set("notification_id", data.id);
        this.registerInboxNotifications(data.id);
        break;
      case "response":
        if (data.ok === false) {
          throw Error(data.error);
        }
        break;
      case "inbox": {
        const event = new Event("notification:new_inbox_message");
        event.data = data;
        this.dispatchEvent(event);
        break;
      }
      case "msg":
      default: {
        const event = new Event(data.sub || data.type || "info");
        event.data = data.msg;
        this.dispatchEvent(event);
      }
    }
  }
}

/*
const ws = new WS(url, token);
ws.connect();
ws.addEventListener('notification:new_inbox_message', e => console.log(e.data));
*/
