import { setupFetch, getAxiosResponse } from "@/helpers/fetch";
import { composeUriQuery } from "@/helpers/uri-query";
import Cookie from "js-cookie";

const apiUrl = import.meta.env.VITE_API_PROXY_URL && !process.client && !/^http/.test(import.meta.env.VITE_API_URL)
    ? `${import.meta.env.VITE_API_PROXY_URL}${import.meta.env.VITE_API_URL}`
    : import.meta.env.VITE_API_URL;
const _fetch = setupFetch(`${apiUrl}/chat-server/api/v1/`, {
  headers: { "Content-Type": "application/json" },
});

export class ChatService extends EventTarget {
  constructor() {
    super();
    this.ws = null;
    this.jsonRpcId = 0;
    this.pendingPromises = {};
  }

  apiCall(method, data = {}) {
    const token = Cookie.get("accessToken");
    if (!token) {
      return Promise.reject("Not logged-in");
    }
    return _fetch(method, {
      method: "POST",
      body: JSON.stringify({
        access_token: token,
        ...data,
      }),
    });
  }

  jsonRpcCall(method, params) {
    return new Promise((resolve, reject) => {
      if (this.ws != null) {
        this.pendingPromises[++this.jsonRpcId] = { resolve, reject };
        this.ws.send(
          JSON.stringify({
            id: this.jsonRpcId,
            jsonrpc: "2.0",
            method,
            params,
          })
        );
      } else {
        const error = new Error("You are offline");
        const event = new CustomEvent("chat:error", { detail: error });
        this.dispatchEvent(event);
        reject();
      }
    });
  }

  initWS(token) {
    return new Promise((resolve, reject) => {
      const wsURL =
        import.meta.env.VITE_CHAT_URI ||
        `wss://${location.host}/chat-server/chat`;
      this.ws = new WebSocket(`${wsURL}/${token}`);
      this.ws.onopen = () => {
        const event = new CustomEvent("chat:online");
        this.dispatchEvent(event);
        resolve();
      };
      this.ws.onmessage = (e) => {
        const message = JSON.parse(e.data);
        if (message.jsonrpc == "2.0") {
          if (message.error) {
            const error = new Error(
              `JSON-RPC error: ${message.error.message}, code: ${message.error.code}`
            );
            const event = new CustomEvent("chat:error", { detail: error });
            this.dispatchEvent(event);
            if (message.id) {
              this.pendingPromises[message.id].reject();
              delete this.pendingPromises[message.id];
            }
          } else {
            if (message.id) {
              this.pendingPromises[message.id].resolve(message.result);
              delete this.pendingPromises[message.id];
            }
          }
        } else if (message.notification) {
          const notification = message.notification;
          switch (notification.type) {
            case "chat":
              this.dispatchEvent(
                new CustomEvent("chat:chat", { detail: notification.chat })
              );
              break;
            case "msg":
              this.dispatchEvent(
                new CustomEvent("chat:message", { detail: notification.msg })
              );
              break;
            case "presence":
              this.dispatchEvent(
                new CustomEvent("chat:presence", {
                  detail: notification.presence,
                })
              );
              break;
          }
        }
      };
      this.ws.onclose = (e) => {
        this.ws = null;
        const event = new CustomEvent("chat:offline");
        this.dispatchEvent(event);
        if (e.code !== 1000) {
          this.reconnect();
        }
      };
      this.ws.onerror = (event) => {
        reject(event);
      };
    });
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.apiCall("auth")
        .then(({ data }) => {
          if (data.ok && data.token) {
            this.initWS(data.token)
              .then(() => resolve())
              .catch((ex) => reject(ex));
          } else {
            reject(new Error("Authentication error"));
          }
        })
        .catch((error) => reject(error));
    });
  }

  sleep(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async reconnect() {
    await this.sleep();
    this.connect().catch(() => this.reconnect());
  }

  loadChats() {
    return this.jsonRpcCall("loadChats");
  }

  load(chatId, offset, limit) {
    return this.jsonRpcCall("loadChat", {
      chat_id: chatId,
      offset,
      limit,
    });
  }

  start(userId) {
    return this.jsonRpcCall("startChat", { user_id: userId });
  }

  send(chatId, message) {
    return this.jsonRpcCall("sendMessage", {
      chat_id: chatId,
      msg: message,
    });
  }

  leave(chatId) {
    return this.jsonRpcCall("leaveChat", { chat_id: chatId });
  }

  reset() {
    if (this.ws) {
      this.ws.close(1000);
    }
    this.jsonRpcId = 0;
    this.pendingPromises = {};
  }
}
