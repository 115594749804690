import { Model } from "@/stores/models/Model";

export class ChatMessage extends Model {
  constructor(raw = {}) {
    super();
    //this.id = Number(raw.id);

    this.userId = String(raw.user_id || "");
    this.text = String(raw.text || "");
    this.createdAt = new Date(raw.created_at);
    this.isSystem = Boolean(raw.is_system);
    this.isOutbound = Boolean(raw.is_outbound);
  }
}
