<template>
  <div class="salon-location__modal">
    <div class="salon-location__modal-inner">
      <span class="salon-location__modal-close" @click="close"></span>
      <div class="salon-location__modal-text mt-2">Ваш город это</div>

      <template v-if="isSearchMode">
        <input
          ref="input"
          v-model.trim="search"
          class="salon-location__modal-input print-city"
          type="text" />

        <div class="salon-location__modal-items">
          <div
            v-for="(cityOption, cityOptionIndex) in cityOptions"
            :key="cityOptionIndex"
            class="salon-location__modal-item"
            @click.stop="select(cityOption)"
            v-html="highlightMatch(cityOption.city)"></div>

          <div
            v-if="search && cityOptions.length === 0"
            class="salon-location__modal-text second">
            Совпадений не найдено
          </div>
        </div>
      </template>

      <template v-else>
        <input
          class="salon-location__modal-input location-name"
          :value="city"
          type="text"
          readonly />

        <div class="salon-location__modal-text second">Верно?</div>

        <div class="salon-location__modal-buttons">
          <button
            class="btn btn-black salon-location__modal-btn yes"
            type="button"
            @click.stop="close">
            Да
          </button>

          <button
            class="btn btn-border salon-location__modal-btn no"
            type="button"
            @click.stop="activateSearch">
            Нет
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import citiesLocales from "@/locales/cities";
export default {
  name: "CitySelectModal",

  props: {
    city: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isSearchMode: false,
      search: "",
      cities: [],
    };
  },

  computed: {
    cityOptions() {
      if (!this.search) return [];
      const searchRegexp = new RegExp(`^${this.search}`, "i");
      return this.cities
        .filter(
          (item) =>
            searchRegexp.test(item.city) || searchRegexp.test(item.region)
        )
        .sort((city1, city2) => {
          const city1Match = searchRegexp.test(city1.city);
          const city2Match = searchRegexp.test(city2.city);
          return city1Match && !city2Match
            ? -1
            : !city1Match && city2Match
            ? 1
            : 0;
        })
        .slice(0, 3);
    },
  },

  watch: {
    async "$i18n.locale"() {
      await this.updateLocaleCities();
    },
  },

  async created() {
    await this.updateLocaleCities();
  },

  methods: {
    async updateLocaleCities() {
      const cities = citiesLocales[this.$i18n.locale] || citiesLocales.ru;
      this.cities = cities?.default || [];
    },

    close() {
      this.$emit("close");
    },

    async activateSearch() {
      this.isSearchMode = true;
      await this.$nextTick();
      this.$refs.input.focus();
    },

    select(value) {
      this.$emit("select", value.city);
      this.close();
    },
    highlightMatch(text) {
      return text.replace(new RegExp(this.search, "ig"), "<b>$&</b>");
    },
  },
};
</script>

<style lang="scss" scoped>
.salon-location__modal,
.salon-location__modal-items {
  display: block;
}
</style>
