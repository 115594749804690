<template>
  <router-link to="/user/orders" class="timer">
    <div v-if="leftTime" class="timer__inner">
      <div class="timer__text">{{ formattedLeftTime }}</div>
    </div>
  </router-link>
</template>

<script>
import { mainApi } from "@/stores/api/main-api";
import { Order } from "@/stores/models/Order";

import { formatISODate } from "@/helpers/date";
export default {
  data() {
    return {
      leftTime: 0,
      timerId: -1,
    };
  },

  computed: {
    formattedLeftTime() {
      const MS_IN_MINUTE = 60 * 1000;
      const MS_IN_HOUR = 60 * MS_IN_MINUTE;

      const hours = Math.floor(this.leftTime / MS_IN_HOUR);
      const leftTimeMinutes = this.leftTime - hours * MS_IN_HOUR;
      const minutes = Math.floor(leftTimeMinutes / MS_IN_MINUTE);
      const seconds = Math.floor(
        (leftTimeMinutes - minutes * MS_IN_MINUTE) / 1000
      );

      const time = hours ? [hours, minutes] : [minutes, seconds];

      return time.map((item) => String(item).padStart(2, "0")).join(":");
    },
  },

  created() {
    if (process.client) {
      this.loadOrder();
    }
  },


  beforeDestroy() {
    clearInterval(this.timerId);
  },

  methods: {
    async loadOrder() {
      const accountModule = useAccount();
      if (!accountModule.userId) return;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          start_at: formatISODate(new Date()),
          finish_at: formatISODate(new Date()),
        });
        if (!data.data.length) return;

        const orders = data.data
          .map((item) => new Order(item))
          .filter((item) => item.startAt.getTime() > Date.now())
          .sort((a, b) => a.startAt.getTime() - b.startAt.getTime());
        if (!orders.length) return;

        this.leftTime = orders[0].startAt.getTime() - Date.now();
        this.timerId = setInterval(() => (this.leftTime -= 1000), 1000);
        this.$emit("timer-start");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "timer.scss";
</style>
